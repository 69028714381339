import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import ApeItem from '../components/ApeItem'
import PageTop from '../components/PageTop'

const Home = (props) => {
  const { tokens, tokens_christmas } = props

  return (
    <main id="home" data-branche={'production'}>
      <PageTop title="welcome to the Max Bet Club" subtitle="Your Dashboard" />
      <div className="content">
        <ApesCard
          title="My GAMBLING Apes"
          tokens={tokens}
          collectionToken="0x90ca8a3eb2574f937f514749ce619fdcca187d45"
        />
        <ApesCard
          title="🎄 My Christmas Gambling Apes"
          tokens={tokens_christmas}
          collectionToken="0x0160fb79e65bec0f16d750098951c26950adc5ed"
        />
        <UtilitiesCard />
      </div>
    </main>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

const mapsStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    tokens: state.app.tokens,
    tokens_christmas: state.app.tokens_christmas,
  }
}

export default withRouter(connect(mapsStateToProps, mapDispatchToProps)(Home))

const ApesCard = ({ title, tokens, collectionToken }) => {
  const [isShowAll, setIsShowAll] = useState(false)

  useEffect(() => {
    if (tokens.length <= 7) setIsShowAll(true)
  }, [tokens])

  const renderApeItems = () => {
    if (tokens && tokens.length > 0) {
      const items = isShowAll ? tokens : tokens.slice(0, 7)
      return items.map((token, i) => (
        <ApeItem tokenId={token} key={i} collectionToken={collectionToken} />
      ))
    } else {
      return [<ApeItem tokenId={0} key={0} collectionToken={collectionToken} />]
    }
  }

  return (
    <div className="apes content_area" data-aos="fade-up" data-aos-once="true">
      <div className="block_title">{title}</div>
      <div className="block_content apes_content">
        <div className="subtitle">
          You own
          <b>{' ' + tokens.length + ' '}</b>
          {tokens.length === 1 ? 'Ape' : 'Apes'}
        </div>
        <div className="content_row d_flex">{renderApeItems()}</div>
        {!isShowAll && <ShowAllBtn onClick={() => setIsShowAll(true)} />}
      </div>
    </div>
  )
}

const UtilitiesCard = () => {
  return (
    <div
      className="utility content_area"
      data-aos="fade-up"
      data-aos-once="true"
      data-aos-delay="100"
    >
      <div className="block_title">YOUR Utility</div>
      <div className="block_content">
        <div className="content_row d_flex">
          <Link to="/earnings">
            <img src="/assets/img/home/utility_1.svg" alt="Earnings" />
            <span>EARNINGS</span>
          </Link>
          <Link to="/competitions">
            <img src="/assets/img/home/utility_2.svg" alt="Competitions" />
            <span>COMPETITIONS</span>
          </Link>
          <Link to="/events">
            <img src="/assets/img/home/utility_3.svg" alt="Events" />
            <span>EVENTS</span>
          </Link>
          <Link to="/merch">
            <img src="/assets/img/home/utility_4.svg" alt="Merch" />
            <span>MERCH</span>
          </Link>
          {/*
					<Link to="/perks">
						<img src="/assets/img/home/utility_5.svg" />
						<span>PERKS</span>
					</Link>
					*/}
        </div>
      </div>
    </div>
  )
}

const ShowAllBtn = ({ onClick }) => (
  <button className="btn show-all" onClick={onClick}>
    Show all
    <svg
      width="21"
      height="10"
      viewBox="0 0 21 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5H20M20 5L16 1M20 5L16 9"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </button>
)
