export const NFT_ADDRESS = '0x90cA8a3eb2574F937F514749ce619fDCCa187d45'
export const NFT_ADDRESS_CHRISTMAS =
  '0x0160fb79e65bec0f16d750098951c26950adc5ed'
export const NETWORK_CHAIN_ID = 1

export const TWITTER_LINK = 'https://twitter.com/gamblingapes'
export const OPENSEA_LINK = 'https://opensea.io/collection/gamblingapes'
export const DISCORD_LINK = 'https://discord.gg/gamblingapes'
export const INSTAGRAM_LINK = 'https://instagram.com/gamblingapes'
// export const ADMIN_API_URL = process.env === "production" ? "https://mbcapi-dev.gamblingapes.com" : "http://localhost:1337"
export const ADMIN_API_URL = 'https://mbcapi-dev.gamblingapes.com'
